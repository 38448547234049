import zkLogo from '@common/assets/logo_park.png'
import judanlogo from '@common/assets/logo_judan.png'
import maoqinglogo from '@common/assets/logo_maoqing.png'
import weidianlogo from '@common/assets/logo_weidian.png'
import judanLogoTitle from '@common/assets/logo_title_judan.svg'
import maoqingLogoTitle from '@common/assets/logo_title_maoqin.svg'
import weidianLogoTitle from '@common/assets/logo_title_weidian.svg'
const env = process.env
const mode = env.VUE_APP_BASE_ENV

//const name = env.VUE_APP_PUBLIC_TITLE
const logoTitle: Record<string, string> = {
  testing: judanLogoTitle,
  preview: judanLogoTitle,
  judandev: judanLogoTitle,
  judanprod: judanLogoTitle,
  development: judanLogoTitle,
  production: judanLogoTitle,
  maoqing: maoqingLogoTitle,
  weidian: weidianLogoTitle,
}

const logo: Record<string, string> = {
  testing: zkLogo,
  preview: zkLogo,
  judandev: judanlogo,
  judanprod: judanlogo,
  development: judanlogo,
  production: judanlogo,
  maoqing: maoqinglogo,
  weidian: weidianlogo,
}
const company: Record<string, string> = {
  testing: '中科巨旦科技',
  preview: '中科巨旦科技',
  judandev: '中科巨旦科技',
  judanprod: '中科巨旦科技',
  development: '中科巨旦科技',
  production: '中科巨旦科技',
  maoqing: '茂清科技',
  weidian: '微点科技',
}
const name: Record<string, string> = {
  testing: '中科巨旦科技',
  preview: '中科巨旦科技',
  judandev: '中科巨旦科技',
  judanprod: '中科巨旦科技',
  development: '中科巨旦科技',
  production: '中科巨旦科技',
  maoqing: '茂清科技',
  weidian: '微点科技',
}

const copyright: Record<string, string> = {
  testing: '杭州巨旦科技有限公司',
  preview: '杭州巨旦科技有限公司',
  judandev: '杭州巨旦科技有限公司',
  judanprod: '杭州巨旦科技有限公司',
  development: '杭州巨旦科技有限公司',
  production: '杭州巨旦科技有限公司',
  maoqing: '茂清科技',
  weidian: '杭州微点创新科技有限公司',
}

const description: Record<string, string> = {
  testing: '泛智慧楼宇解决专家',
  preview: '泛智慧楼宇解决专家',
  judandev: '数智空间解决专家',
  judanprod: '数智空间解决专家',
  development: '数智空间解决专家',
  production: '数智空间解决专家',
  maoqing: '数智空间解决专家',
  weidian: '数智空间解决专家',
}

export default {
  logo: logo[mode],
  logoTitle: logoTitle[mode],
  company: company[mode],
  copyright: copyright[mode],
  description: description[mode],
  name: name[mode],
}
