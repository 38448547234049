/**
 * 解析传入的JSON数据
 * @param data 解析的数据
 * @param initial 解析失败的初始值
 * @returns
 */
export function jsonParser(data: unknown, initial: unknown = null) {
  if (typeof data !== 'string') return initial
  try {
    return JSON.parse(data)
  } catch (error) {
    return initial
  }
}

/**
 * 通过JSON复制数据
 * @param data 复制的数据
 * @returns
 */
export function jsonCopied<T>(data: T): T {
  return JSON.parse(JSON.stringify(data))
}
