import { isDef, isUndef } from '@tri-admin/utils'
import { TriEventDetail } from '@devotee/views'

type JoinByCommaData = string | number | string[] | number[] | Record<string, any>[] | undefined
type JoinByCommaItem = string | number | Record<string, any>

export const shelves = { activeValue: 0, inactiveValue: 1, activeText: '上架' }
export const regionMaps = ['provinceCode', 'cityCode', 'areaCode', 'streetCode']
export const isNumOrStr = (data: unknown): boolean => typeof data === 'string' || typeof data === 'number' // 是数字或者字符串
export const datatimeJoin = ({ startTime, endTime }: Record<string, any>) => `${startTime}-${endTime}` // 时间数组转字符串
export const datetimeArray = (data: Record<string, any>) => [data.startTime, data.endTime] // 时间数组转字符串
export const datatimeObject = ({ value: [startTime, endTime] }: any) => ({ startTime, endTime }) // 时间数组转字符串

/**
 * 浅复制对象
 * @param target 复制目标
 * @param exclude 剔除不复制的内容
 * @returns
 */
export function copiedExclude(target: Record<string, any>, exclude?: string[]): Record<string, any> {
  if (!exclude) return Object.assign({}, target)

  const result: Record<string, any> = {}
  for (const item of Object.keys(target)) {
    if (exclude.includes(item)) continue
    result[item] = target[item]
  }
  return result
}

/**
 * 首字母大写转化
 * @param items
 */
export function takeCapitalize(items = []) {
  return items.map((item: string) => item.slice(0, 1).toUpperCase() + item.slice(1)).join('')
}

// 获取
export function traverseMatched(target: any, items: any[]) {
  let result: any = target
  const count = items.length
  for (let index = 0; index < count; index++) {
    const elem = items[index]
    const item = result.find((row: any) => row.name === elem.name)
    if (isUndef(item)) return []
    if (item.meta.submenu === true) return [item]
    result = item.children
  }
}

/**
 * 用逗号来拼接成字符串
 * @param data 需要拼接的数据
 * @param prop 拼接属性
 * @returns
 */
export function joinByComma(data?: JoinByCommaData, prop = 'id'): string {
  if (isNumOrStr(data)) return String(data)
  if (Array.isArray(data)) return data.map((item: JoinByCommaItem) => (isNumOrStr(item) ? item : (item as Record<string, any>)[prop])).join(',')
  return ''
}

/**
 * 分割字符串
 * @param data 字符串
 * @param isNumber 是否返回数字数组
 * @returns
 */
export function splitByComma(data?: string, isNumber = false): string[] | number[] {
  if (Array.isArray(data)) return data
  if (data) {
    const list = data.split(',').filter((item: string) => isDef(item))
    return isNumber ? list.map((item: string) => Number(item)) : list
  }
  return []
}

/**
 * 区域提交数据格式化
 * @returns
 */
export function regionToObj({ value }: TriEventDetail): Record<string, any> {
  if (!Array.isArray(value)) return {}
  return regionMaps.reduce((total: Record<string, any>, item: string, index: number) => Object.assign(total, isDef(value[index]) ? { [item]: value[index] } : {}), {})
}

/**
 * 区域显示序列号
 * @returns
 */
export function regionToArr({ data }: TriEventDetail): string[] | number[] {
  const list: string[] | number[] = []
  regionMaps.forEach((item: string) => (data[item] ? list.push(data[item] as never) : null))
  return list
}

/**
 * 从数组中取出单个属性的值
 * @param data 数组对象
 * @param prop 取值属性
 */
export function takeAtFromArray(data?: Record<string, any>[], prop = 'id') {
  if (Array.isArray(data)) {
    return data.map((item: Record<string, any>) => item[prop])
  }
  return []
}

/**
 * 生成日期时间字符串
 * @param format 格式化字符串
 * @param date 传入日期
 * @returns
 */
const formatter = (num: number): string => (isNaN(num) ? '' : num < 10 ? `0${num}` : num.toString())
export function timeFormatter(format = 'YYYY-MM-DD hh:mm:ss', date: string | number = ''): string {
  const target: Date = new Date(date) // 时间构造
  const content: string = target.toString() // 时间字符串
  const weeksZh: string[] = ['日', '一', '二', '三', '四', '五', '六']
  const weeksEn: string[] = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fir', 'Sat']
  const datetime: Date = content.includes('Invalid') ? new Date() : target // 无效日期时取当前时间

  const week: number = datetime.getDay()
  const year: number = datetime.getFullYear()
  const month: number = datetime.getMonth() + 1
  const dates: number = datetime.getDate()
  const hours: number = datetime.getHours()
  const minute: number = datetime.getMinutes()
  const second: number = datetime.getSeconds()
  const result: { [prop: string]: string | number } = {
    Y: year,
    M: month,
    D: dates,
    W: weeksZh[week],
    h: hours,
    m: minute,
    s: second,
    w: weeksEn[week],
  }

  return format.replace(/(Y|M|D|h|m|s|W|w)+/gi, (val: string, key: string): string => {
    const value: number | string = result[key]
    const count: number = val.length
    if (isUndef(value)) return ''
    if (key === 'Y') return String(value).slice(-count)
    if (['w', 'W'].includes(key)) return String(value)
    return count > 1 ? formatter(Number(value)) : String(value)
  })
}

/**
 * 判断两数组字符串是否相同（用于按钮权限验证），数组字符串中存在相同时会自动去重（按钮权限标识不会重复）
 * @param news 新数据
 * @param old 源数据
 * @returns 两数组相同返回 `true`，反之则反
 */
export function judementSameArr(newArr: unknown[] | string[], oldArr: string[]): boolean {
  const news = removeDuplicate(newArr)
  const olds = removeDuplicate(oldArr)
  let count = 0
  const leng = news.length
  for (const i in olds) {
    for (const j in news) {
      if (olds[i] === news[j]) count++
    }
  }
  return count === leng ? true : false
}

/**
 * 数组、数组对象去重
 * @param arr 数组内容
 * @param attr 需要去重的键值（数组对象）
 * @returns
 */
export function removeDuplicate(arr: any[], attr?: string) {
  if (!Object.keys(arr).length) {
    return arr
  } else {
    if (attr) {
      const obj: any = {}
      return arr.reduce((cur: any[], item: any) => {
        obj[item[attr]] ? '' : (obj[item[attr]] = true && item[attr] && cur.push(item))
        return cur
      }, [])
    } else {
      return [...new Set(arr)]
    }
  }
}
