import { joinByComma } from '@common/utils/utils'

const rules = [
  { required: true, message: '请输入旧密码', trigger: 'blur' },
  { pattern: /^([a-zA-Z0-9_*-+~.=&%$#@]{6,16})$/, message: '请输入由大小写字母，数字以及符号组成的6-16位密码' },
]

export const actions = [{ icon: 'TriIconNotice', emit: 'notice', label: '通知', badge: 'notReadMessageCount' }]
export const dropdown = [
  { icon: 'TriIconHouse', emit: 'navigate', label: '驾驶舱', url: 'http://fudan.casrh.com/#/home' },
  { icon: 'TriIconUser', emit: 'update', label: '个人信息', divided: true },
  { icon: 'TriIconSwitch', emit: 'switch', label: '切换园区', divided: true },
  { icon: 'TriIconLock1', emit: 'raised', label: '修改密码', divided: true },
  { icon: 'TriIconClear', emit: 'remove', label: '清空缓存', divided: true },
  { icon: 'TriIconQuit', emit: 'logout', label: '退出登录', divided: true },
]
export const parkList = [{ type: 'select', prop: 'tenantId', label: '园区选择', rule: 'select' }]
export const verifyList = [
  { type: 'block', prop: 'remind' },
  { type: 'input', prop: 'password', label: '输入密码' },
  { type: 'hidden', prop: 'tenantId' },
]
export const personList = [
  { type: 'phone', prop: 'phone', label: '手机号码', rule: 'phone', disabled: true },
  { type: 'input', prop: 'name', label: '姓名', rule: 'input' },
  {
    type: 'image',
    prop: 'image',
    label: '头像',
    rule: 'select',
    extend: { name: 'file', limit: 1 },
    formatter: ({ value }: any) => joinByComma(value),
    serializer: ({ data }: any) => data.image || [],
  },
]
export const passwordList = [
  { type: 'input', prop: 'oldPassword', label: '旧密码', width: '102px', rule: rules, clearable: false },
  { type: 'input', prop: 'newPassword', label: '新密码', width: '102px', rule: rules, clearable: false },
  { type: 'input', prop: 'confirmThePassword', label: '确认新密码', width: '102px', extend: { password: 'newPassword' }, rule: 'password2', clearable: false },
]
