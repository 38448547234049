import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import TriLayout from '@/layout/TriLayout.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: TriLayout,
    meta: { title: '首页', hidden: true },
  },
  {
    path: '/login',
    component: () => import('@views/login.vue'),
    meta: { title: '登录', hidden: true },
  },
  {
    path: '/remind',
    component: () => import('@views/remind.vue'),
    meta: { title: '温馨提示', hidden: true },
  },
  {
    path: '/assetsdetails',
    component: () => import('@views/assets/assetsH5.vue'),
    meta: { title: '资产详情', hidden: true },
  },
  {
    path: '/notice',
    name: 'NoticePage',
    component: TriLayout,
    redirect: '/notice/list',
    meta: { title: '通知列表', hidden: true },
    children: [
      {
        path: '/notice/list',
        name: 'NoticeList',
        component: () => import('@views/notice/notice.vue'),
        meta: { title: '通知列表', hidden: true },
      },
    ],
  },
  {
    path: '/expiredContractBill',
    name: 'ExpiredContractBill',
    component: TriLayout,
    redirect: '/expiredContractBill/list',
    meta: { title: '到期合同账单', hidden: true },
    children: [
      {
        path: '/expiredContractBill/list',
        name: 'ExpiredContractBillList',
        component: () => import('@views/expiredContractBill/list.vue'),
        meta: { title: '到期合同账单列表', hidden: true },
      },
    ],
  },
  {
    path: '/403',
    name: '403',
    component: () => import('@views/error/403.vue'),
    meta: { title: '无权限页面', hidden: true },
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router
