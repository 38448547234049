import type { Ref } from 'vue'
import type { TriVoidFunction, TriPromiseFunction } from '@devotee/views'
import { isArr } from '@tri-admin/utils/src/assert'
import { isRef } from 'vue'

/**
 * 请求接口封装
 * @param request 请求接口
 * @param catchError 错误回调
 * @returns
 */
export default function useRequest(request: TriPromiseFunction, catchError?: (error?: unknown) => void) {
  return function loadData(success: TriVoidFunction | Ref<any>, params?: string | number | Record<string, unknown>) {
    request(params)
      .then((data: any) => (isRef(success) ? (success.value = data) : (success as TriPromiseFunction)(data)))
      .catch((error: any) => {
        if (isRef(success)) success.value = isArr(success.value) ? [] : {}
        if (catchError) return catchError(error)
        console.log(error)
      })
  }
}
