import router from '@/router'
import storage from '@/utils/storage'
import loginApi from '@api/login' // 用户API
import type { AdminLoginForm, ParkItem } from '@common/typing'

export default {
  state: {
    token: storage.getToken() || '',
    parks: storage.get('parks'),
    ticket: '',
  },

  mutations: {
    SET_TOKEN: (state: any, data: string) => (state.token = data), // 设置token
    SET_PARKS: (state: any, data: ParkItem[]) => (state.parks = data), // 设置token
    SET_TICKET: (state: any, data: string) => (state.ticket = data), // 设置浏览token
  },

  // 异步操作
  actions: {
    // 登陆操作
    async Login({ dispatch }: any, form: AdminLoginForm) {
      const data: any = await loginApi.login(form)
      await dispatch('SetToken', data)
    },

    // 退出登陆
    async Logout({ dispatch }: any) {
      await loginApi.logout()
      await dispatch('RemoveToken')
      router
    },

    // 用户园区
    async ParkList({ commit }: any, params: { loginAccount: string }) {
      try {
        const data: any = await loginApi.parks(params)
        const list = data || []
        const items = list.map((item: any) => ({ value: item.parkId, label: item.parkName }))
        commit('SET_PARKS', items)
        storage.set('parks', items)
        return items
      } catch (error) {
        console.error(error)
      }
    },

    // 设置token
    SetToken({ commit }: any, token: string) {
      return new Promise((resolve) => {
        storage.setToken(token)
        commit('SET_TOKEN', token)
        return resolve('ok')
      })
    },

    // 移除token
    async RemoveToken({ commit, dispatch }: any) {
      storage.removeToken()
      storage.remove('parks')
      commit('SET_TOKEN', '')
      commit('SET_PARKS', [])
      await dispatch('app/RemoveCaching', {}, { root: true })
      await dispatch('permission/RemoveCache', {}, { root: true })
      router.push(`/login`)
      return { data: 'ok' }
    },
  },
  namespaced: true,
}
