import { App } from 'vue'
import WidgetTabs from './widget-tabs/widget-tabs'
import WidgetCard from './widget-card/widget-card'
import WidgetBlock from './widget-block/widget-block'
import WidgetChart from './widget-chart/widget-chart.vue'
import WidgetTitle from './widget-title/widget-title.vue'
import WidgetCount from './widget-count/widget-count.vue'
import WidgetImport from './widget-import/widget-import.vue'
import WidgetImportV2 from './widget-import/widget-importV2.vue'
import WidgetTooltip from './widget-tooltip/index.vue'
import WidgetNotice from './widget-notice/widget-notice.vue'
import WidgetServer from './widget-server/widget-server.vue'
import WidgetProgress from './widget-progress/widget-progress'
import './widget-card/style/index.scss'
import './widget-tabs/style/index.scss'
import './widget-progress/style/index.scss'

const TriComponents: any = {}
TriComponents.install = (app: App) => {
  app.component(WidgetCard.name, WidgetCard)
  app.component(WidgetTabs.name, WidgetTabs)
  app.component(WidgetTitle.name, WidgetTitle)
  app.component(WidgetBlock.name, WidgetBlock)
  app.component(WidgetChart.name, WidgetChart)
  app.component(WidgetCount.name, WidgetCount)
  app.component(WidgetNotice.name, WidgetNotice)
  app.component(WidgetImport.name, WidgetImport)
  app.component(WidgetImportV2.name, WidgetImportV2)
  app.component(WidgetServer.name, WidgetServer)
  app.component(WidgetProgress.name, WidgetProgress)
  app.component(WidgetTooltip.name, WidgetTooltip)
}

export default TriComponents
