import ajax from '@/utils/ajax'

/**
 * 公共数据接口
 * @param {object} params 请求参数
 * @param {object} data 提交数据
 * @param {string|number} id 请求参数ID
 * @returns Promise
 */

interface SalesmanList {
  specialRoleAuth: string | number
  parkId: string | number
}

export default {
  roleList: async () => await ajax.get('/userManager/role/getRoleListNoPage'), // 获取角色不分页列表
  getWatchUser: async (param: SalesmanList) => await ajax.get('/sub/user/getWatchUser', param), // 获取业务员
  salesmanList: async (param: SalesmanList) => await ajax.get('/sub/user/getInvestmentUser', param), // 获取业务员
  uploadFile: async (data: any) => await ajax.upload('/sub/file/uploadOne', data), // 上传文件
  regionTree: async (param?: any) => await ajax.get('/sub/region/getTreeList', param), // 上传图片
  regionFour: async (param?: any) => await ajax.get('/sub/region/getAllTreeList', param), // 上传图片
  uploadImg: async (data: any) => await ajax.upload('/sub/file/uploadOne', data), // 上传图片
  dictionary: async () => await ajax.get('/sub/dictionary/list'), // 获取字典列表
  industry: async () => {
    const list = await ajax.get('/sub/industry/getIndustryList')
    const items: { [prop: string]: string | number }[] = list as any
    return items.map((item: any) => ({ value: item.id, label: item.industryName }))
  },
}
