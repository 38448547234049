import { noticeApi } from '@/api/notice'

export default {
  state: {
    list: [],
    count: {},
  },
  mutations: {
    SET_LIST: (state: any, data: Record<string, any>[]) => (state.list = data), // 设置token
    SET_COUNT: (state: any, data: Record<string, any>) => (state.count = data), // 设置token
  },
  actions: {
    // 获取全局消息
    async GetHeaderNotice({ commit }: any) {
      const count = await noticeApi.count()
      const target: any = await noticeApi.list({ pageSize: 3, pageNo: 1 })

      commit('SET_LIST', target.list || [])
      commit('SET_COUNT', count || {})
    },
  },
  namespaced: true,
}
