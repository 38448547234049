import common from '@/api/common'
import storage from './storage'

export default {
  form: {
    margin: '1rem',
    spacing: '0.8rem',
    footerAlign: 'right',
    buttons: [
      { label: '确定', type: 'primary', size: 'default', emit: 'confirm' },
      { label: '取消', type: 'default', size: 'default', emit: 'cancel' },
    ],
  },
  table: {
    empty: { text: '暂无数据源', image: '' },
    button: { size: 'small', link: true },
    cellStyle: { fontSize: 'var(--el-font-size-base)', padding: '0.8rem 0' },
    headCellStyle: { fontSize: 'var(--el-font-size-base)', lineHeight: '1.8rem', fontWeight: 600, padding: '0.8rem 0' },
  },
  upload: {
    file: '/api/sub/common/file/uploadFile',
    image: '/api/sub/common/file/uploadFile',
    headers: () => ({ authorized: storage.getToken(), systemType: 'sub_system' }),
  },
  dialog: {
    width: { mini: '24rem', small: '30rem', normal: '36rem', medium: '42rem', large: '48rem', larger: '54rem' },
    center: true,
    headAlign: 'center',
    footAlign: 'right',
    buttons: [
      { label: '确定', type: 'primary', size: 'default', emit: 'confirm' },
      { label: '取消', type: 'default', size: 'default', emit: 'cancel' },
    ],
  },
  detail: { margin: '0.5rem', spacing: '0.8rem' },
  search: { margin: 0, spacing: '0.8rem' },
  tinymce: { file: common.uploadFile, image: common.uploadImg },
  pagination: { align: 'right', field: { limit: 'pageSize', page: 'pageNo' }, layout: 'total, sizes, prev, pager, next, jumper' },
}
