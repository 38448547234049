export default {
  // 在元素被插入到 DOM 前调用
  beforeMount(el, binding, vnode, prevVnode) {
    el.style.position = 'absolute' // 设置绝对定位
    el.onmousemove = function (e) {
      // 设置鼠标样式为可拖动、手、普通
      el.style.cursor = binding.value.cursorType
    }
    el.onmousedown = function (e) {
      if (!binding.value.canMove) {
        return
      }
      // 鼠标按下事件
      let startX = e.clientX - el.offsetLeft
      let startY = e.clientY - el.offsetTop
      document.onmousemove = function (e) {
        // 鼠标移动事件
        let moveX = e.clientX - startX
        let moveY = e.clientY - startY
        if (binding.value.limitTop && moveY < binding.value.limitTop) {
          //限制拖拽区域
          moveY = binding.value.limitTop
        }
        if (binding.value.limitLeft && moveX < binding.value.limitLeft) {
          //限制拖拽区域
          moveX = binding.value.limitLeft
        }
        el.style.left = moveX + 'px'
        el.style.top = moveY + 'px'
      }
      document.onmouseup = function () {
        // 鼠标释放事件
        document.onmousemove = null
        document.onmouseup = null
      }
    }
  },
}
