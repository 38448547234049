import router from '@/router'
import storage from '@/utils/storage'
import loginApi from '@api/login' // 用户API
import TriLayout from '@/layout/TriLayout.vue'
import TriSubLayout from '@/layout/TriSubLayout.vue'
import { copiedExclude } from '@common/utils/utils'
import { traverseRouter, generateSidemenu, generateQuickmenu, traverseGetIndex } from '@common/utils/permission'
import { jsonCopied } from '@common/utils/copied'
import type { RouteRecordRaw } from 'vue-router'

export default {
  state: {
    logo: '',
    park: '',
    role: 7, // "子管理员": 1, "领导": 3, "成员": 7
    menus: [], // 用户权限路由
    routes: [], // 用户权限路由
    navigate: [],
    homePath: '', // 跳转首页
    userInfo: {},
    contract: false,
    already: false, // 是否加载
    userPark: storage.get('userPark') || '',
  },
  mutations: {
    SET_LOGO: (state: any, data: string | number) => (state.logo = data), // 设置token
    SET_PARK: (state: any, data: string | number) => (state.park = data), // 设置token
    SET_ROLE: (state: any, data: string | number) => (state.role = data), // 设置token
    SET_MENUS: (state: any, data: any) => (state.menus = data),
    SET_ROUTES: (state: any, data: any) => (state.routes = data),
    SET_USERINFO: (state: any, data: any) => (state.userInfo = data), // 设置用户信息
    SET_NAVIGATE: (state: any, data: any) => (state.navigate = data), // 设置用户信息
    SET_CONTRACT: (state: any, data: any) => (state.contract = data), // 设置用户信息
    SET_ALREADY: (state: any, data: any) => (state.already = data),
    SET_HOME_PATH: (state: any, data: any) => (state.homePath = data),
  },
  actions: {
    // 获取用户信息
    async GetInfo({ commit }: any) {
      const data: any = await loginApi.userInfo()
      const menus = data?.menuList || []
      const items = menus.find((item: any) => (item.name = 'Contract'))
      data.avatarId = data.avatarId ? [{ fileId: data.avatarId, fileUrl: data.avatarUrl }] : []
      commit('SET_LOGO', data.parkName)
      commit('SET_PARK', data.parkId)
      commit('SET_ROLE', data.stationType)
      commit('SET_USERINFO', copiedExclude(data, ['menuList']))
      commit('SET_CONTRACT', Boolean(items))
      return jsonCopied(data.menuList)
    },

    // 获取用户路由权限
    async generateRoutes({ commit, dispatch }: any) {
      const data = await dispatch('GetInfo')
      const items = traverseRouter(data, 'childList', { TriLayout, TriSubLayout })
      const menus = generateSidemenu(data)
      const quick = generateQuickmenu(data)
      const index = traverseGetIndex(items)
      items.forEach((element: RouteRecordRaw) => router.addRoute(element))
      commit('SET_MENUS', menus)
      commit('SET_NAVIGATE', quick)
      commit('SET_ROUTES', items)
      commit('SET_ALREADY', true)
      commit('SET_HOME_PATH', index)
      router.addRoute({ path: '/:catchAll(.*)', name: '404', component: () => import('@views/error/404.vue') })
      return index ? Promise.resolve({ code: 200 }) : Promise.reject({ code: 9403, msg: '没有页面权限' })
    },

    // 移除token
    RemoveCache({ commit }: any) {
      return new Promise((resolve) => {
        commit('SET_PARK', '')
        commit('SET_ROLE', '')
        commit('SET_USERINFO', {})
        commit('SET_CONTRACT', false)
        commit('SET_MENUS', [])
        commit('SET_ROUTES', [])
        commit('SET_NAVIGATE', [])
        commit('SET_ALREADY', false)
        commit('SET_HOME_PATH', '')
        return resolve('ok')
      })
    },
  },
  namespaced: true,
}
