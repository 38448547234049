<template>
  <router-view v-slot="{ Component }">
    <component :is="Component" />
  </router-view>
</template>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;
  transform-origin: 0 0;
  background-color: var(--el-bg-color-page);
}
</style>
