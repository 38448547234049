import { h, ref, onMounted, defineComponent } from 'vue'

export default defineComponent({
  name: 'WidgetProgress',
  props: {
    color: { type: String, default: 'linear-gradient(90deg, var(--el-color-primary) 0%, var(--el-color-primary-light-5) 100%)' },
    label: { type: String, default: '' },
    format: { type: Function, default: null },
    showValue: { type: Boolean, default: false },
    percentage: { type: [String, Number], default: '' },
    strokeWidth: { type: [String, Number], default: 8 },
  },
  emits: ['switch', 'update:modelValue'],
  setup(props) {
    const wdProgressBar = ref<HTMLElement>()
    onMounted(() => {
      if (wdProgressBar.value) {
        const barWidth = Math.round((Number(props.percentage) / 1) * 100) / 100
        wdProgressBar.value.style.width = barWidth + '%'
      }
    })

    return () => {
      const count = Number(props.percentage)
      const value = props.format ? props.format(props.percentage) : `${count}%`
      const height = /^\d+$/.test(String(props.strokeWidth)) ? `${props.strokeWidth}px` : props.strokeWidth
      const classname = ['wd-progress__line', { 'have-label': props.label, 'have-value': props.showValue }]
      const vNodeLabel = props.label ? h('span', { class: 'wd-progress__label' }, props.label) : ''
      const vNodeValue = props.showValue ? h('span', { class: 'wd-progress__value' }, value) : ''
      const vNodeLineBar = h('i', { ref: wdProgressBar, class: 'wd-progress__bar', style: { background: props.color } })
      const vNodeProgress = h('span', { class: classname, style: { height } }, vNodeLineBar)
      return h('div', { class: 'wd-progress' }, [vNodeLabel, vNodeProgress, vNodeValue])
    }
  },
})
