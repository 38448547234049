import { App } from 'vue'
import TriIconAccount from './src/account.vue'
import TriIconAdmin from './src/admin.vue'
import TriIconAfforest from './src/afforest.vue'
import TriIconAnnex from './src/annex.vue'
import TriIconApplet from './src/applet.vue'
import TriIconApprove from './src/approve.vue'
import TriIconAssets from './src/assets.vue'
import TriIconAvatar from './src/avatar.vue'
import TriIconBuilding from './src/building.vue'
import TriIconBusiness from './src/business.vue'
import TriIconCheckout from './src/checkout.vue'
import TriIconCleaning from './src/cleaning.vue'
import TriIconClear from './src/clear.vue'
import TriIconClient from './src/client.vue'
import TriIconComplaint from './src/complaint.vue'
import TriIconComputer from './src/computer.vue'
import TriIconCountdown from './src/countdown.vue'
import TriIconCrash from './src/crash.vue'
import TriIconCustomer from './src/customer.vue'
import TriIconDashboard from './src/dashboard.vue'
import TriIconDelete from './src/delete.vue'
import TriIconDialplate from './src/dialplate.vue'
import TriIconDiary from './src/diary.vue'
import TriIconDigital from './src/digital.vue'
import TriIconDoors from './src/doors.vue'
import TriIconEquipment from './src/equipment.vue'
import TriIconEstate from './src/estate.vue'
import TriIconEye from './src/eye.vue'
import TriIconFinance from './src/finance.vue'
import TriIconFinance1 from './src/finance1.vue'
import TriIconFirm from './src/firm.vue'
import TriIconFloor from './src/floor.vue'
import TriIconGuard from './src/guard.vue'
import TriIconHouse from './src/house.vue'
import TriIconHydropower from './src/hydropower.vue'
import TriIconLock1 from './src/lock1.vue'
import TriIconLook from './src/look.vue'
import TriIconLotcenter from './src/lotcenter.vue'
import TriIconManage from './src/manage.vue'
import TriIconMeeting from './src/meeting.vue'
import TriIconMenus from './src/menus.vue'
import TriIconMerchants from './src/merchants.vue'
import TriIconNotice from './src/notice.vue'
import TriIconOnduty from './src/onduty.vue'
import TriIconOverview from './src/overview.vue'
import TriIconParking from './src/parking.vue'
import TriIconPassword from './src/password.vue'
import TriIconPatrol from './src/patrol.vue'
import TriIconPlay from './src/play.vue'
import TriIconPortion from './src/portion.vue'
import TriIconProperties from './src/properties.vue'
import TriIconQuery from './src/query.vue'
import TriIconQuit from './src/quit.vue'
import TriIconRecord from './src/record.vue'
import TriIconRegion from './src/region.vue'
import TriIconRemind from './src/remind.vue'
import TriIconRenovation from './src/renovation.vue'
import TriIconRepair from './src/repair.vue'
import TriIconRepairs from './src/repairs.vue'
import TriIconRooms from './src/rooms.vue'
import TriIconSearch from './src/search.vue'
import TriIconSecurity from './src/security.vue'
import TriIconSelected from './src/selected.vue'
import TriIconService from './src/service.vue'
import TriIconSetting from './src/setting.vue'
import TriIconSettings from './src/settings.vue'
import TriIconStar from './src/star.vue'
import TriIconStop from './src/stop.vue'
import TriIconSwitch from './src/switch.vue'
import TriIconTenant from './src/tenant.vue'
import TriIconTip from './src/tip.vue'
import TriIconUpload from './src/upload.vue'
import TriIconUser from './src/user.vue'
import TriIconVehicle from './src/vehicle.vue'
import TriIconVisitor from './src/visitor.vue'

const TriSvgComponent: any = {}
TriSvgComponent.install = (app: App) => {
  app.component(TriIconAccount.name, TriIconAccount)
  app.component(TriIconAdmin.name, TriIconAdmin)
  app.component(TriIconAfforest.name, TriIconAfforest)
  app.component(TriIconAnnex.name, TriIconAnnex)
  app.component(TriIconApplet.name, TriIconApplet)
  app.component(TriIconApprove.name, TriIconApprove)
  app.component(TriIconAssets.name, TriIconAssets)
  app.component(TriIconAvatar.name, TriIconAvatar)
  app.component(TriIconBuilding.name, TriIconBuilding)
  app.component(TriIconBusiness.name, TriIconBusiness)
  app.component(TriIconCheckout.name, TriIconCheckout)
  app.component(TriIconCleaning.name, TriIconCleaning)
  app.component(TriIconClear.name, TriIconClear)
  app.component(TriIconClient.name, TriIconClient)
  app.component(TriIconComplaint.name, TriIconComplaint)
  app.component(TriIconComputer.name, TriIconComputer)
  app.component(TriIconCountdown.name, TriIconCountdown)
  app.component(TriIconCrash.name, TriIconCrash)
  app.component(TriIconCustomer.name, TriIconCustomer)
  app.component(TriIconDashboard.name, TriIconDashboard)
  app.component(TriIconDelete.name, TriIconDelete)
  app.component(TriIconDialplate.name, TriIconDialplate)
  app.component(TriIconDiary.name, TriIconDiary)
  app.component(TriIconDigital.name, TriIconDigital)
  app.component(TriIconDoors.name, TriIconDoors)
  app.component(TriIconEquipment.name, TriIconEquipment)
  app.component(TriIconEstate.name, TriIconEstate)
  app.component(TriIconEye.name, TriIconEye)
  app.component(TriIconFinance.name, TriIconFinance)
  app.component(TriIconFinance1.name, TriIconFinance1)
  app.component(TriIconFirm.name, TriIconFirm)
  app.component(TriIconFloor.name, TriIconFloor)
  app.component(TriIconGuard.name, TriIconGuard)
  app.component(TriIconHouse.name, TriIconHouse)
  app.component(TriIconHydropower.name, TriIconHydropower)
  app.component(TriIconLock1.name, TriIconLock1)
  app.component(TriIconLook.name, TriIconLook)
  app.component(TriIconLotcenter.name, TriIconLotcenter)
  app.component(TriIconManage.name, TriIconManage)
  app.component(TriIconMeeting.name, TriIconMeeting)
  app.component(TriIconMenus.name, TriIconMenus)
  app.component(TriIconMerchants.name, TriIconMerchants)
  app.component(TriIconNotice.name, TriIconNotice)
  app.component(TriIconOnduty.name, TriIconOnduty)
  app.component(TriIconOverview.name, TriIconOverview)
  app.component(TriIconParking.name, TriIconParking)
  app.component(TriIconPassword.name, TriIconPassword)
  app.component(TriIconPatrol.name, TriIconPatrol)
  app.component(TriIconPlay.name, TriIconPlay)
  app.component(TriIconPortion.name, TriIconPortion)
  app.component(TriIconProperties.name, TriIconProperties)
  app.component(TriIconQuery.name, TriIconQuery)
  app.component(TriIconQuit.name, TriIconQuit)
  app.component(TriIconRecord.name, TriIconRecord)
  app.component(TriIconRegion.name, TriIconRegion)
  app.component(TriIconRemind.name, TriIconRemind)
  app.component(TriIconRenovation.name, TriIconRenovation)
  app.component(TriIconRepair.name, TriIconRepair)
  app.component(TriIconRepairs.name, TriIconRepairs)
  app.component(TriIconRooms.name, TriIconRooms)
  app.component(TriIconSearch.name, TriIconSearch)
  app.component(TriIconSecurity.name, TriIconSecurity)
  app.component(TriIconSelected.name, TriIconSelected)
  app.component(TriIconService.name, TriIconService)
  app.component(TriIconSetting.name, TriIconSetting)
  app.component(TriIconSettings.name, TriIconSettings)
  app.component(TriIconStar.name, TriIconStar)
  app.component(TriIconStop.name, TriIconStop)
  app.component(TriIconSwitch.name, TriIconSwitch)
  app.component(TriIconTenant.name, TriIconTenant)
  app.component(TriIconTip.name, TriIconTip)
  app.component(TriIconUpload.name, TriIconUpload)
  app.component(TriIconUser.name, TriIconUser)
  app.component(TriIconVehicle.name, TriIconVehicle)
  app.component(TriIconVisitor.name, TriIconVisitor)
}

export default TriSvgComponent
export {
  TriIconAccount,
  TriIconAdmin,
  TriIconAfforest,
  TriIconAnnex,
  TriIconApplet,
  TriIconApprove,
  TriIconAssets,
  TriIconAvatar,
  TriIconBuilding,
  TriIconBusiness,
  TriIconCheckout,
  TriIconCleaning,
  TriIconClear,
  TriIconClient,
  TriIconComplaint,
  TriIconComputer,
  TriIconCountdown,
  TriIconCrash,
  TriIconCustomer,
  TriIconDashboard,
  TriIconDelete,
  TriIconDialplate,
  TriIconDiary,
  TriIconDigital,
  TriIconDoors,
  TriIconEquipment,
  TriIconEstate,
  TriIconEye,
  TriIconFinance,
  TriIconFinance1,
  TriIconFirm,
  TriIconFloor,
  TriIconGuard,
  TriIconHouse,
  TriIconHydropower,
  TriIconLock1,
  TriIconLook,
  TriIconLotcenter,
  TriIconManage,
  TriIconMeeting,
  TriIconMenus,
  TriIconMerchants,
  TriIconNotice,
  TriIconOnduty,
  TriIconOverview,
  TriIconParking,
  TriIconPassword,
  TriIconPatrol,
  TriIconPlay,
  TriIconPortion,
  TriIconProperties,
  TriIconQuery,
  TriIconQuit,
  TriIconRecord,
  TriIconRegion,
  TriIconRemind,
  TriIconRenovation,
  TriIconRepair,
  TriIconRepairs,
  TriIconRooms,
  TriIconSearch,
  TriIconSecurity,
  TriIconSelected,
  TriIconService,
  TriIconSetting,
  TriIconSettings,
  TriIconStar,
  TriIconStop,
  TriIconSwitch,
  TriIconTenant,
  TriIconTip,
  TriIconUpload,
  TriIconUser,
  TriIconVehicle,
  TriIconVisitor,
}
