import type { Component } from 'vue'
import button from '../../web.button.js'

interface ActionItem {
  type: string
  data: { label: string; emit: string; type: string; icon: Component }
}

export function takeActions(type = 'page') {
  const filter = button.filter((item: ActionItem) => item.type === type)
  return filter.reduce((total: Record<string, string>, item: ActionItem) => Object.assign(total, { [item.data.emit]: item.data.label }), {})
}

export function takeButtons(type = 'page') {
  const filter = button.filter((item: ActionItem) => item.type === type)
  return filter.reduce((total: Record<string, string>, item: ActionItem) => Object.assign(total, { [item.data.emit]: item.data }), {})
}

export default takeButtons('table')
