import { jsonCopied } from '@common/utils/copied'
import { isDatetime } from '@tri-admin/utils'

const maps: Record<string, string> = { 0: '周日', 1: '周一', 2: '周二', 3: '周三', 4: '周四', 5: '周五', 6: '周六' } // 星期文字
const time = ['00:00:00', '23:59:59'] // 时间字符串

export function clearEmptyParam(config: any) {
  ;['data', 'params'].forEach((item) => {
    if (config[item]) {
      const keys = Object.keys(config[item])
      if (keys.length) {
        keys.forEach((key) => {
          const rawType = toRawType(config[item])
          if (['', undefined, null].includes(config[item][key]) && ['Object'].includes(rawType)) {
            // 移除属性之前，进行深拷贝断开引用，避免影响页面
            config[item] = jsonCopied(config[item])
            delete config[item][key]
          }
        })
      }
    }
  })
}

/**
 * @description 获取原始类型
 * @param {*} value
 * @returns {String} 类型字符串，如'String', 'Object', 'Null', 'Boolean', 'Number', 'Array'
 */
export function toRawType(value: string) {
  return Object.prototype.toString.call(value).slice(8, -1)
}

export function dynamicWeeks(date: string[]) {
  let week = 0
  const list = date.map((item: string, index: number) => (isDatetime(item) ? item : `${item} ${time[index]}`))
  const count = 24 * 3600000
  const start = Date.parse(list[0])
  const minus = Date.parse(list[1]) - start
  const number = Math.ceil(minus / count)
  const weekList: { day: number; week: string; stamp: number }[] = []

  week = new Date(list[0]).getDay()

  for (let index = 0; index < number; index++) {
    weekList.push({ day: week, week: maps[week], stamp: start + index * count } as any)
    week + 1 > 6 ? (week = 0) : (week = week + 1)
  }

  return weekList
}

export function convertToChinaNum(num: number) {
  const arr1 = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
  const arr2 = ['', '十', '百', '千', '万', '十', '百', '千', '亿', '十', '百', '千', '万', '十', '百', '千', '亿'] //可继续追加更高位转换值
  if (!num || isNaN(num)) {
    return '零'
  }
  const english = num.toString().split('')
  let result = ''
  for (let i = 0; i < english.length; i++) {
    const des_i = english.length - 1 - i //倒序排列设值
    result = arr2[i] + result
    const arr1_index: any = english[des_i]
    result = arr1[arr1_index] + result
  }
  //将【零千、零百】换成【零】 【十零】换成【十】
  result = result.replace(/零(千|百|十)/g, '零').replace(/十零/g, '十')
  //合并中间多个零为一个零
  result = result.replace(/零+/g, '零')
  //将【零亿】换成【亿】【零万】换成【万】
  result = result.replace(/零亿/g, '亿').replace(/零万/g, '万')
  //将【亿万】换成【亿】
  result = result.replace(/亿万/g, '亿')
  //移除末尾的零
  result = result.replace(/零+$/, '')
  //将【零一十】换成【零十】
  //result = result.replace(/零一十/g, '零十');//貌似正规读法是零一十
  //将【一十】换成【十】
  result = result.replace(/^一十/g, '十')
  return result
}
