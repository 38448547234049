import type { Ref } from 'vue'
import type { TriLayoutProps } from '@tri-admin/layout/dist/typings'
import { ref, watchEffect } from 'vue'

export default function useLayout(params: Ref<{ layout: string; collapse: boolean }>): Ref<TriLayoutProps> {
  const target = ref<TriLayoutProps>({ name: 'viewport', class: 'tri-viewport tri-horizontal tri-layout', direction: 'row' })
  function takeLayout(params: Ref<{ layout: string; collapse: boolean }>) {
    const option = params.value
    if (option.layout === 'horizontal') {
      target.value = {
        name: 'viewport',
        class: 'tri-viewport tri-horizontal tri-layout',
        direction: 'row',
        children: [
          {
            name: 'sidebar',
            class: 'tri-sidebar',
            width: option.collapse ? 'var(--tri-sidebar-collapse-width)' : 'var(--tri-sidebar-width)',
            direction: 'column',
            children: [{ name: 'logo' }, { name: 'menus', class: 'tri-menus' }, { name: 'server' }, { name: 'submenu' }],
          },
          {
            tag: 'section',
            name: 'container',
            class: 'tri-container',
            direction: 'column',
            children: [
              {
                tag: 'header',
                name: 'header',
                class: 'tri-header',
                children: [
                  { name: 'left', class: 'tri-header__left', children: [{ name: 'unfold' }, { name: 'breadcrumbCustom' }] },
                  { name: 'right', class: 'tri-header__right', children: [{ name: 'actions' }, { name: 'theme' }, { name: 'account' }] },
                ],
              },
              { name: 'content' },
              { name: 'footer' },
            ],
          },
        ],
      }
    } else if (option.layout === 'vertical') {
      target.value = {
        name: 'viewport',
        class: 'tri-viewport tri-vertical tri-layout',
        direction: 'column',
        children: [
          {
            tag: 'header',
            name: 'header',
            class: 'tri-header',
            children: [
              { name: 'left', class: 'tri-header__left', children: [{ name: 'logo' }] },
              { name: 'right', class: 'tri-header__right', children: [{ name: 'actions' }, { name: 'theme' }, { name: 'account' }] },
            ],
          },
          {
            tag: 'section',
            name: 'container',
            class: 'tri-container',
            direction: 'row',
            children: [{ name: 'sidebar', class: 'tri-sidebar', direction: 'column', children: [{ name: 'menus' }, { name: 'server' }] }, { name: 'content' }, { name: 'digital' }],
          },
        ],
      }
    } else {
      target.value = {
        name: 'viewport',
        class: 'tri-viewport tri-vertical tri-layout',
        direction: 'column',
        children: [
          {
            tag: 'header',
            name: 'header',
            class: 'tri-header',
            children: [
              { name: 'left', class: 'tri-header__left', children: [{ name: 'logo' }] },
              { name: 'right', class: 'tri-header__right', children: [{ name: 'actions' }, { name: 'theme' }, { name: 'account' }] },
            ],
          },
          {
            tag: 'section',
            name: 'container',
            class: 'tri-container',
            direction: 'row',
            children: [{ name: 'sidebar', class: 'tri-sidebar', direction: 'column', children: [{ name: 'menus' }, { name: 'server' }] }, { name: 'content' }, { name: 'digital' }],
          },
        ],
      }
    }
  }
  watchEffect(() => takeLayout(params))
  return target
}
