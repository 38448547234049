import { h, defineComponent, PropType } from 'vue'

interface OptionItem {
  value: string
  label: string
}

export default defineComponent({
  name: 'WidgetTabs',
  props: {
    tabs: { type: Array as PropType<OptionItem[]>, default: () => [] },
    height: { type: [String, Number], default: 42 },
    spacing: { type: [String, Number], default: 20 },
    modelValue: { type: [String, Number], default: '' },
  },
  emits: ['switch', 'update:modelValue'],
  setup(props, { emit, slots }) {
    return () => {
      const height = /^\d+$/.test(String(props.height)) ? `${props.height}px` : props.height
      const margin = /^\d+$/.test(String(props.spacing)) ? `${props.spacing}px` : props.spacing

      const vSlotMore = slots.more
      const vNodeItem = props.tabs.map((item: OptionItem, index: number) => {
        const handler = () => {
          emit('update:modelValue', item.value)
          emit('switch', { value: item.value, index, data: item })
        }
        const classname = ['widget-tabs__item', { 'is-active': props.modelValue === item.value }]
        return h('li', { key: index, class: classname, onClick: handler }, item.label)
      })

      const vNodeList = h('ul', { class: 'widget-tabs__list' }, vNodeItem)
      const vNodeMore = vSlotMore ? h('div', { class: 'widget-tabs__more' }, vSlotMore()) : ''

      return h('div', { class: 'widget-tabs', style: { height: height, marginTop: margin, marginBottom: margin } }, [vNodeList, vNodeMore])
    }
  },
})
