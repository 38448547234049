import ajax from '@/utils/ajax'
import type { AdminLoginForm } from '@common/typing'

export default {
  parks: async (params: { loginAccount: string }) => ajax.get(`/auth/getCurrentUserAllPark`, params), // 园区列表
  login: async (data: AdminLoginForm) => ajax.post('/auth/subAccountLogin', data), // 登陆系统
  logout: async () => ajax.get('/sub/system/user/logout'), // 退出登陆
  userInfo: async () => ajax.get('/sub/system/user/getCurrentUserInfo'), // 读取当前用户信息
  editUser: async (data: any) => ajax.put('/sub/system/user/updateUserInfo', data), // 编辑用户信息
  updatePassword: async (data: any) => ajax.put(`/sub/system/user/updatePassword`, data),
  changeUserPark: async (data: any) => ajax.put(`/sub/system/user/changeUserPark`, data), // 切换用户状态--切换园区
}
