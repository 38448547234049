import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import TriView from '@devotee/views'
import ElementPlus from 'element-plus'
import TriGlobalSettings from '@/utils/global'
import TriComponents from '@common/components/global'
import TriSvgIcon from '@common/components/tri-icon'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import VueAMap, { initAMapApiLoader } from '@vuemap/vue-amap'
import 'default-passive-events'
// @ts-ignore
import { v4 as uuidv4 } from 'uuid'
import draggable from '@/utils/draggable.js'
// 加载js
import '@/permission'

// 加载样式
import '@tri-admin/layout/dist/css/style.css'
import 'tri-views/dist/css/index.css'
import '@common/style/index.scss'
import 'nprogress/nprogress.css'

initAMapApiLoader({
  key: '1acd1d42c8c8d829405315fbc7b80edb',
  securityJsCode: '0e53076e32d1304d6862802e2f452252',
  plugins: ['AMap.DistrictSearch'],
})
const app = createApp(App)
app.config.globalProperties.uuidv4 = uuidv4
app.directive('draggable', draggable)
app.use(store).use(router).use(ElementPlus, { locale: zhCn }).use(TriView, TriGlobalSettings).use(TriComponents).use(TriSvgIcon).use(VueAMap).mount('#app')
