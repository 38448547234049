
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TriIconParking',
  props: {
    size: { type: String, default: '18px' },
    color: { type: String, default: 'currentColor' },
  },
})
