import ajax from '@/utils/ajax'

/**
 * 账号管理的增删改查API
 * @param {object} params 请求参数
 * @param {object} data 提交数据
 * @param {string|number} id 请求参数
 * @returns Promise
 */
export const AccountApi = {
  roll: async () => await ajax.get(`/sub/system/user/getRoleList`), // 角色列表
  list: async (id: number | string) => await ajax.get(`/sub/system/user/getUserListByRoleId/${id}`), // 用户列表
  userListRoleTree: async () => await ajax.get(`/sub/system/user/getUserListRoleTree`),
  adminOrganizationByTree: async () => await ajax.get('/sub/system/user/getAdminOrganizationByTree'), // 组织管理列表
  userListByOrganizationId: async (organizationId: number | string) => await ajax.get(`/sub/system/user/getUserListByOrganizationId/${organizationId}`),
}

export const RoleApi = {
  roleList: async () => await ajax.get(`/sub/system/user/getRoleList`), //全部角色
  personnelList: async (id: number | string) => await ajax.get(`/sub/system/user/getUserListByRoleId/${id}`),
}
