import axios from 'axios'
import store from '@/store'
import router from '@/router'
import { ElMessage, ElMessageBox } from 'element-plus'
import type { ElMessageBoxOptions } from 'element-plus'
import type { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import { clearEmptyParam } from './utils'
import { isUndef } from 'tri-views/src/utils/assert'
import URLParser from './location'

const baseURL = process.env.VUE_APP_BASE_API || '' // api的base_url
const message = '账号登录信息失效,请重新登录！'
const instance = axios.create({ baseURL: baseURL, timeout: 30000, withCredentials: true }) // 创建实例

const msgboxOption: ElMessageBoxOptions = { type: 'warning', center: true, showCancelButton: false, confirmButtonText: '确定', lockScroll: false }

// 退出登陆
HandleLogout.status = false
function HandleLogout(msg = message) {
  if (HandleLogout.status) return
  HandleLogout.status = true
  ElMessageBox.confirm(msg, '重新登陆', msgboxOption)
    .then(async () => {
      HandleLogout.status = false
      await store.dispatch('user/RemoveToken')
      router.push('/login')
    })
    .catch((error: any) => {
      ElMessage.error('退出登陆失败')
      return Promise.reject(error)
    })
}

// 错误扑捉
function ErrorCapturer(error: AxiosError) {
  if (error?.message.includes('timeout')) return ElMessage({ type: 'error', message: '网络缓慢，请更换网络' })
  if (!error.response) return ElMessage({ type: 'error', message: error.message })
  const target: AxiosResponse = error.response
  if (target.status === 401) return HandleLogout()
  if (target.status === 404) return ElMessage({ type: 'error', message: '请求接口不存在' })
  return ElMessage({ type: 'error', message: target.data.msg || target.data.message })
}

// 请求拦截
instance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  // 查案详情
  if (isUndef(store.getters.ticket)) {
    const ticket: string = URLParser.takeURLParam('ticket')
    if (ticket) store.commit('user/SET_TICKET', ticket)
  }

  if (store.getters.ticket && config.headers) {
    const ticket: string = URLParser.takeURLParam('ticket')
    if (ticket !== store.getters.ticket) store.commit('user/SET_TICKET', ticket)
    store.commit('user/SET_TOKEN', '')
    config.headers['Authorized'] = store.getters.ticket
    config.headers['Access-Control-Allow-Origin'] = '*'
    config.headers['systemType'] = 'sub_system'
    clearEmptyParam(config)
    return config
  }

  if (store.getters.token) config.headers['authorized'] = store.getters.token
  config.headers['systemType'] = 'sub_system'
  config.headers['Access-Control-Allow-Origin'] = '*'
  clearEmptyParam(config)
  return config
}, ErrorCapturer)

// 响应拦截
instance.interceptors.response.use((response: AxiosResponse) => {
  const target = response.data || {}
  const request = response.request || {}
  const resType = request.responseType
  if (resType === 'blob') return target // 响应类型为Blob时直接返回target
  if (target.code === 200) return target.data // 返回响应数据
  if (target.code === 401) {
    HandleLogout() // 退出登陆
    return Promise.reject(target)
  }

  ElMessage({ type: 'error', message: target.msg || target.message })
  return Promise.reject(target.msg || target.message) // 没有返回数据时抛出异常，统一提示
}, ErrorCapturer)

export default instance
