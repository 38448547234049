import type { PropType } from 'vue'
import type { TriButtonColumn } from '@devotee/views'
import { h, reactive, defineComponent, watch } from 'vue'
import { ElOption, ElSelect, ElDatePicker } from 'element-plus'
import { useButtonList } from 'tri-views/packages/hooks/use-button'
import { isDef } from '@tri-admin/utils'

export declare interface FilterOption {
  prop: string
  type: string
  extend: Record<string, any>
  children: { value: string; label: string }[]
}

export default defineComponent({
  name: 'WidgetCard',
  props: {
    data: { type: Object as PropType<Record<string, string | number>>, default: () => null },
    title: { type: String, default: '' },
    filter: { type: Array as PropType<FilterOption[]>, default: () => [] },
    action: { type: Array as PropType<TriButtonColumn[]>, default: () => [] },
    spacing: { type: [String, Number], default: '' },
  },
  setup(props, { emit, slots }) {
    const form: { [prop: string]: any } = reactive({})
    const onPicker = (value: string, prop: string) => {
      form[prop] = value
      emit('picker', form)
    }

    watch(
      () => props.data,
      (now: Record<string, string | number>, old?: Record<string, string | number>) => {
        if (now && now !== old) {
          for (const item of Object.keys(now)) {
            const value = props.data[item]
            form[item] = isDef(value) ? value : ''
          }
          emit('picker', form)
        }
      },
      { immediate: true }
    )

    return () => {
      const { title, filter, spacing, action } = props
      const margin = /^\d+$/.test(String(spacing)) ? `${spacing}px` : spacing
      const vSlotMain = slots.default
      const vSlotTitle = slots.title
      const vSlotRight = slots.right
      const vNodeTitle = vSlotTitle ? vSlotTitle(props) : title ? h('h3', { class: 'widget-card__title' }, title) : ''
      const vNodeButton = useButtonList(action, (event: TriButtonColumn) => emit(event.emit, event))

      const vNodeSelect = filter.map((group: FilterOption) => {
        const value = form[group.prop]
        if (group.type === 'date') {
          return h(ElDatePicker, {
            modelValue: value,
            valueFormat: 'YYYY-MM-DD',
            ...(group.extend ?? {}),
            onChange: (value: string) => onPicker(value, group.prop),
            'onUpdate:modelValue': (value: string | number) => (form[group.prop] = value),
          })
        }

        const items = group.children || []
        const vNodes = items.map((item: { label: string; value: string }, index: number) => h(ElOption, { key: index, label: item.label, value: item.value }))
        return h(ElSelect, { modelValue: value, onChange: (value: string) => onPicker(value, group.prop) }, () => vNodes)
      })

      const vNodeAction = h('div', { class: 'widget-card__action' }, [vNodeButton, vNodeSelect])
      const vNodeHeader = vNodeTitle ? h('div', { class: 'widget-card__head' }, [vNodeTitle, vSlotRight ? vSlotRight(props) : vNodeAction]) : ''
      const vNodeContent = h('div', { class: 'widget-card__body' }, [vSlotMain ? vSlotMain(props) : ''])

      return h('div', { class: 'widget-card', style: { marginBottom: margin } }, [vNodeHeader, vNodeContent])
    }
  },
})
