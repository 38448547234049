import { takeActions } from '@common/configs/actions'
import { traverseAddFun } from '@tri-admin/utils'
import { takeCapitalize } from '@common/utils/utils'
import type { TriRouteMetaParam } from '@common/typing'

const pageActions = takeActions() // 页面按钮
const tableActions = takeActions('table') // 表格操作按钮

/**
 * 生成页面路由
 * @param target 路由转换目标
 * @param children 子级属性名称
 * @param param2 参数
 * @returns
 */
export function traverseRouter(target: any, children = 'children', { TriLayout, TriSubLayout }: any): any {
  if (Array.isArray(target)) return target.map((item: any) => traverseRouter(item, children, { TriLayout, TriSubLayout }))

  const path = target.path || '/' // 路径字符
  const list = path.split('/').filter((item: string) => Boolean(item)) // 路径数组
  const name = takeCapitalize(list) // 路由名字
  const meta: TriRouteMetaParam = {
    icon: target.icon,
    title: target.menuName,
    table: [],
    pages: [],
    remark: target.remark,
    hidden: Boolean(target.hideStatus),
    submenu: !target.menuStatus,
  }
  const items = target[children] || [] // 路由子级
  const redirect = items[0]?.path || ''

  // 一级目录
  if (target.menuType == 1) {
    return {
      path: path,
      name: name,
      meta: Object.assign(meta, { dir: true }),
      redirect,
      component: TriLayout,
      children: items.map((item: any) => traverseRouter(item, children, { TriLayout, TriSubLayout })),
    }
  } else if (target.menuType == 2) {
    // 二级菜单
    if (items.length <= 0) return { path, name, meta, component: () => import(`@/views${target.menuDir}.vue`) }

    // 三级菜单
    if (items.every((item: any) => item.menuType !== 3)) {
      return {
        path,
        name,
        meta: Object.assign(meta, { dir: true }),
        redirect,
        component: TriSubLayout,
        children: items.map((item: any) => traverseRouter(item, children, { TriLayout, TriSubLayout })),
      }
    } else {
      // 操作按钮
      for (const it of items) {
        if (!it.path) continue
        const action = it.path.match(/(?<=:)([a-zA-Z]+)$/)[0]
        if (pageActions[action]) meta.pages.push(action)
        if (tableActions[action]) meta.table.push(action)
      }
      return { path, name, meta, component: () => import(`@/views${target.menuDir}.vue`) }
    }
  }
}

/**
 * 生成侧边菜单
 * @param target 路由转换目标
 * @returns
 */
export function generateSidemenu(target: Record<string, any>[]) {
  const items: Record<string, any>[] = []
  for (const item of target) {
    if (item.hideStatus === 1) continue
    if (item.shortcutMenu === 1) continue

    const path = item.path || '/' // 路径字符
    const list = path.split('/').filter((item: string) => Boolean(item)) // 路径数组
    const name = takeCapitalize(list) // 路由名字

    items.push({
      path: item.outChainStatus === 0 ? item.outChainUrl : item.path,
      name: name,
      meta: { icon: item.icon, title: item.menuName, hidden: Boolean(item.hideStatus) },
    })
  }
  return items
}

/**
 * 生成快捷菜单
 * @param target 路由转换目标
 * @returns
 */
export function generateQuickmenu(target: Record<string, any>[]) {
  const items: Record<string, any>[] = []
  const formatter = (item: Record<string, any>) => {
    const path = item.outChainStatus === 0 ? item.outChainUrl : item.path
    return { path, name: item.name, meta: { icon: item.icon, title: item.menuName, order: item.menuOrder } }
  }

  traverseAddFun(target, (item: any) => item.shortcutMenu !== 0 && items.push(formatter(item)), 'childList')
  return items.sort((prev: any, next: any) => prev.order - next.order)
}

export function traverseGetIndex(target: any): string {
  if (Array.isArray(target)) {
    let result = ''
    for (const item of target) {
      result = traverseGetIndex(item)
      if (result) break
    }
    return result
  } else {
    const items = target.children || []
    if (items.length > 0) return traverseGetIndex(items)
    return target.meta.dir ? false : target.name
  }
}
