import { isDef } from 'tri-views/src/utils/assert'

export default class URLParser {
  // 接卸search
  static parser(query: string): Record<string, string> {
    const items: string[] = query.split(/\s*&\s*/).filter((item?: string) => Boolean(item))
    const target: Record<string, string> = {}

    items.forEach((item: string) => {
      const [key, val] = item.split(/\s*=\s*/)
      isDef(key) ? (target[key] = val) : null
    })

    return target
  }

  // 获取URL
  static locationURL(url?: string) {
    return url ? url.split('?')[0] : window.location.origin
  }

  // 获取Search
  static locationSearch(url?: string): Record<string, string> {
    if (url) {
      const search = url.match(/(?<=\?)[^#|^/]+/)
      return search ? URLParser.parser(search[0]) : ({} as any)
    } else {
      const hash = window.location.hash
      const search = window.location.search
      const target = search.match(/(?<=\?)[^#|^/]+/)
      if (target) return URLParser.parser(target[0])

      if (hash.includes('?')) {
        const valid = hash.match(/(?<=\?)[^#|^/]+/)
        return valid ? URLParser.parser(valid[0]) : ({} as any)
      }
      return {} as any
    }
  }

  // 获取参数
  static takeURLParam(name: string, url?: string) {
    const query = URLParser.locationSearch(url)
    return query[name] ?? ''
  }
}
