import type { PropType } from 'vue'
import type { TriButtonColumn } from '@devotee/views'

import { h, defineComponent } from 'vue'
import { useButtonList } from 'tri-views/packages/hooks/use-button'
import { ElScrollbar } from 'element-plus'
import './style/index.scss'

export default defineComponent({
  name: 'WidgetBlock',
  props: {
    title: { type: String, default: '' },
    height: { type: String, default: 'calc(100% - 4.8rem)' },
    scroll: { type: Boolean, default: false },
    actions: { type: Array as PropType<TriButtonColumn[]>, default: () => [] },
  },
  setup(props, { emit, slots }) {
    return () => {
      const { title, height, scroll, actions } = props
      const visible = title || (actions && actions.length > 0)
      const vSlotNode = slots.default ? slots.default() : ''
      const vSlotTitle = slots.title ? slots.title() : ''
      const vSlotButton = slots.button ? slots.button() : ''
      const vNodeTitle = h('div', { class: 'widget-block__title' }, [h('span', { class: 'text' }, title)])

      const vNodeButton = useButtonList(actions, (data: TriButtonColumn) => emit(data.emit, data))
      const vNodeAction = h('div', { class: 'widget-block__button' }, [vSlotButton, vNodeButton])
      const vNodeHeader = h('div', { class: 'widget-block__head' }, [vSlotTitle ? vSlotTitle : vNodeTitle, vNodeAction])
      const vNodeScroll = h('div', { class: 'widget-block__roll' }, [h(ElScrollbar, { class: 'widget-block__view' }, () => [vSlotNode])])
      const vNodeViewer = h('div', { class: 'widget-block__body' }, [vSlotNode])
      const vNodeContent = scroll ? vNodeScroll : vNodeViewer

      return h('div', { class: 'widget-block', style: `height: ${height}` }, [visible ? vNodeHeader : '', vNodeContent])
    }
  },
})
