import app from './modules/app'
import user from './modules/user'
import notice from './modules/notice'
import permission from './modules/permission'
import { createStore } from 'vuex'

const env = process.env
const mode = env.NODE_ENV
export default createStore({
  state: {
    width: 1920,
    title: env.VUE_APP_PUBLIC_TITLE,
    formal: ['development', 'production', 'judandev', 'judanprod'].includes(mode),
  },
  getters: {
    theme: (state: any) => state.app.theme,
    token: (state: any) => state.user.token,
    parks: (state: any) => state.user.parks,
    count: (state: any) => state.notice.count, // 通知数量
    notice: (state: any) => state.notice.list, // 通知列表
    options: (state: any) => state.app.options,
    members: (state: any) => state.app.members,
    userTree: (state: any) => state.app.userTree,
    userInfo: (state: any) => state.permission.userInfo,
    userPark: (state: any) => state.permission.userPark,
    contract: (state: any) => state.permission.contract,
    navigate: (state: any) => state.permission.navigate,
    already: (state: any) => state.permission.already,
    routes: (state: any) => state.permission.routes,
    index: (state: any) => state.permission.homePath,
    menus: (state: any) => state.permission.menus,
    role: (state: any) => state.permission.role,
    park: (state: any) => state.permission.park,
    logo: (state: any) => state.permission.logo,
    ticket: (state: any) => state.user.ticket,
  },
  mutations: {},
  actions: {},
  modules: { app, user, notice, permission },
})
