import common from '@/api/common'
import storage from '@/utils/storage'
import { AccountApi } from '@/api/account'
import type { TriCascaderItem, TriOptionItem } from '@common/typing'

const status = storage.get('sidebarStatus') || 1

export default {
  state: {
    theme: storage.get('theme') || {},
    device: 'desktop',
    sidebar: {
      opened: !!status,
      withoutAnimation: false,
    },
    members: storage.get('members') || {},
    options: storage.get('options') || {},
    userTree: storage.get('userTree') || {},
    cachedViews: [],
  },
  mutations: {
    SET_THEME: (state: any, data: Record<string, TriOptionItem[]>) => ((state.theme = data), storage.set('theme', data)),
    SET_OPTIONS: (state: any, data: Record<string, TriOptionItem[]>) => (state.options = data),
    SET_MEMBERS: (state: any, data: Record<string, TriOptionItem[]>) => (state.members = data),
    SET_USER_TREE: (state: any, data: TriCascaderItem[]) => (state.userTree = data),
    SET_ROLE_LIST: (state: any, data: TriOptionItem[]) => (state.roleId = data),
    SET_SALESMAN_LIST: (state: any, data: TriOptionItem[]) => (state.salesmanList = data),

    TOGGLE_SIDEBAR: (state: any) => {
      state.sidebar.opened = !state.sidebar.opened
      state.sidebar.withoutAnimation = false
      if (state.sidebar.opened) {
        storage.set('sidebarStatus', 1)
      } else {
        storage.set('sidebarStatus', 0)
      }
    },
    CLOSE_SIDEBAR: (state: any, withoutAnimation: string) => {
      storage.set('sidebarStatus', 0)
      state.sidebar.opened = false
      state.sidebar.withoutAnimation = withoutAnimation
    },
    TOGGLE_DEVICE: (state: any, device: string) => (state.device = device),
    CACHE_VIEWS: (state: any, { data, bool }: any) => {
      const index = state.cachedViews.indexOf(data)
      if (bool && index === -1) state.cachedViews.push(data)
      if (!bool && index > -1) state.cachedViews.splice(index, 1)
      console.log(state.cachedViews)
    },
  },
  actions: {
    toggleSideBar({ commit }: any) {
      commit('TOGGLE_SIDEBAR')
    },
    closeSideBar({ commit }: any, { withoutAnimation }: any) {
      commit('CLOSE_SIDEBAR', withoutAnimation)
    },
    toggleDevice({ commit }: any, device: any) {
      commit('TOGGLE_DEVICE', device)
    },

    // 获取角色列表
    async GetRoleList() {
      const data: any = await AccountApi.roll()
      const list = data ?? []
      return list.map(({ id, roleName }: any) => ({ value: id, label: roleName }))
    },
    // 获取角色业务人员树结构
    async GetUserListRoleTree() {
      const data: any = await AccountApi.userListRoleTree()
      const list = data ?? []
      return list.filter((item: any) => item?.adminUserSimpleVOList?.length)?.map((m: any) => ({ ...m, name: m.roleName }))
    },

    // 获取业务员
    async GetSalesman(target: any, id: string | number) {
      const data: any = await AccountApi.list(id)
      const list = data ?? []
      return list.map(({ userId, userName }: any) => ({ value: userId, label: userName }))
    },

    // 获取字典列表
    async GetDictionary({ commit }: any) {
      const data: any = await common.dictionary()
      // const options1 = data.map((m: any) => {
      //   return {
      //     id: m.id,
      //     code: m.dictCode,
      //     name: m.dictName,
      //     children: m.adminDictionaryItemList?.map((item: any) => ({
      //       value: item.itemValue,
      //       label: item.itemText,
      //       status: item.description,
      //     })),
      //   }
      // })
      // console.log(JSON.stringify(options1))
      const list: Record<string, any>[] = data ?? []
      const options = list.reduce((total: Record<string, TriOptionItem[]>, group: any) => {
        const items = group.adminDictionaryItemList || []
        const target = items.map((item: any) => ({
          value: isNaN(item.itemValue) ? item.itemValue : Number(item.itemValue),
          label: item.itemText,
          status: item.description ?? '',
        }))
        return Object.assign(total, { [group.dictCode]: target })
      }, {})
      storage.set('options', options)
      commit('SET_OPTIONS', options)
    },

    // 获取角色人员树
    async GetAllMembers({ commit }: any) {
      const list: TriOptionItem[] = []
      const tree: TriCascaderItem[] = []
      const data: any = await AccountApi.userListRoleTree()
      const items: any = data ?? []
      for (const item of items) {
        if (item.adminUserSimpleVOList && item.adminUserSimpleVOList.length > 0) {
          tree.push({
            value: item.id,
            label: item.roleName,
            children: item.adminUserSimpleVOList.map((it: any) => {
              const target = { value: it.id, label: it.name }
              list.push(target)
              return target
            }),
          })
        }
      }
      storage.set('members', list)
      storage.set('userTree', tree)
      commit('SET_MEMBERS', list)
      commit('SET_USER_TREE', tree)
    },

    // 获取本地存储数据
    async GetLocalCaching({ dispatch }: any) {
      await dispatch('GetDictionary')
      await dispatch('GetAllMembers')
    },

    async RemoveCaching({ commit }: any) {
      storage.remove('options')
      storage.remove('members')
      storage.remove('userTree')
      commit('SET_OPTIONS', {})
      commit('SET_MEMBERS', [])
      commit('SET_USER_TREE', [])
    },
  },
  namespaced: true,
}
