import ajax from '@/utils/ajax'

/**
 * 通知接口API
 * @param {object} params 请求参数
 * @param {object} data 提交数据
 * @param {string|number} id 请求参数ID
 * @returns Promise
 */
export const noticeApi = {
  type: async () => await ajax.get('/sub/message/queryMessageCentre'), // 获取所有消息中心
  list: async (params: any) => await ajax.get('/sub/message/page', params), // 根据消息类型获取所有消息
  read: async (data: any) => await ajax.post('/sub/message/readMessageById', data), // 全部已读
  count: async () => await ajax.get('/sub/message/getUnreadMessageCount'), // 获取未读消息数
  allRead: async () => await ajax.get('/sub/message/readAllMessage'), // 全部已读
  batchRead: async (data: any) => await ajax.post('/sub/message/readMessage', data), // 总览数据批量删除
  queryList: async (params: any) => await ajax.get(`/sub/message/getMessageByTypeId`, params), // 总览数据详情
}
