import store from './store'
import router from './router'
import NProgress from 'nprogress' // 进度条
import URLParser from '@/utils/location'
import { isUndef } from '@tri-admin/utils/src/assert'
const whiteTemporary = ['/assetsTemporary'] // 路由下面所有白名单
const whiteList = ['/system/workflow', '/login', '/403', '/404', '/remind', '/notice', '/assetsdetails', '/notice/list', '/expiredContractBill/list'] // 白名单
const setDocTitle = (valid: string, title = '') => (document.title = [valid, title].join('-'))

NProgress.configure({ showSpinner: false })
router.beforeEach(async (to: any, from: any, next: any) => {
  NProgress.start()
  // 临时页面后期删除
  const isTemporary = whiteTemporary.some((item) => to.matched?.[0]?.path === item)

  const state = store.state
  const title = to.meta.title
  const isAlready = store.getters.already
  // 临时修改
  setDocTitle(title, isTemporary ? '资产管理' : state.title) // 设置标签名称

  // 获取url中的ticket
  if (isUndef(store.getters.ticket)) {
    const ticket: string = URLParser.takeURLParam('ticket')
    if (ticket) store.commit('user/SET_TICKET', ticket)
  }

  if (store.getters.ticket) {
    to.meta.pages = []
    to.meta.table = []
  }

  if (window.innerWidth >= 1024) {
    const redirect = to.query.redirect

    if (store.getters.token || store.getters.ticket) {
      if (to.path === '/login') {
        next({ path: redirect ?? '/' })
        NProgress.done()
      } else {
        if (isAlready) {
          if (to.name) {
            next()
          } else if (store.getters.index) {
            next({ name: store.getters.index, replace: true })
          } else {
            next({ path: `/403?redirect=${to.path}`, replace: true })
          }
        } else {
          try {
            await store.dispatch('permission/generateRoutes')
            if (from.path === '/login' && store.getters.index) {
              redirect ? next({ path: redirect }) : next({ name: store.getters.index })
            } else {
              next({ ...to, replace: true })
            }
          } catch (error: any) {
            console.log(error)
            if (error.code === 9403) {
              next({ path: `/403?redirect=${to.path}`, replace: true })
            } else if (error.code === 401) {
              await store.dispatch('user/RemoveToken')
              next({ path: `/login?redirect=${to.path}`, replace: true })
            } else {
              next()
            }
          }
        }
      }
    } else {
      if (whiteList.indexOf(to.path) !== -1 || isTemporary) {
        next()
      } else {
        next(`/login?redirect=${to.path}`)
        NProgress.done()
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1 || isTemporary) {
      next()
    } else {
      next({ path: '/remind', query: { redirect: to.path } })
    }
  }
})

router.afterEach(() => NProgress.done())
